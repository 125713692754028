import { type CommonSystemProps } from "tailwind-config";
import { spacingVariant } from "tailwind-config";

interface WithFloatingLayoutProps extends CommonSystemProps {
  children: React.ReactNode;
  floatingUnit?: React.ReactNode;
  className?: string;
}

export const WithFloatingLayout = ({
  children,
  floatingUnit,
  className,
  ...systemProps
}: WithFloatingLayoutProps) => {
  return (
    <div
      className={`tw-flex tw-flex-col tw-h-full tw-relative ${className} ${spacingVariant(
        {
          ...systemProps
        }
      )}`}
    >
      <div className={"tw-flex-1 tw-overflow-y-auto tw-overflow-x-hidden"}>
        {children}
      </div>
      {floatingUnit ? (
        <div className="tw-fixed tw-bottom-0 tw-w-full tw-px-20 tw-py-24">
          {floatingUnit}
        </div>
      ) : null}
    </div>
  );
};
