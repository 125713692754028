export const ERROR_CONTENT = {
  SERVER_ERROR: {
    title: "서버 네트워크 오류",
    messages: ["오류가 계속된다면 고객센터로 문의해주세요"]
  },
  CLIENT_ERROR: {
    title: "알 수 없는 오류가 발생했습니다",
    messages: ["오류가 계속된다면 고객센터로 문의해주세요"]
  }
};

export const RIDING_SERVER_ERROR_CONTENT = {
  "007-005": {
    title: "현재 이용 불가한 자전거입니다",
    messages: [
      "주변의 다른 자전거로 시도해주시면 \n분명 좋은 시간이 될 거에요!"
    ]
  },
  "007-004": {
    title: "현재 이용 불가한 자전거입니다",
    messages: ["지금 계신 지역에서는 이용이 불가하오니\n조금만 기다려 주세요!"]
  }
};

export const AUTH_ERROR_CONTENT = {
  "007-002": {
    title: "가입 이력이 없는 경우에만\n 앱 없이 이용이 가능해요",
    messages: [
      "아쉽지만 쏘카일레클을 설치해서 이용해주세요.\n 오래 걸리지 않아요!"
    ]
  },
  "001-050": {
    title: "가입 이력이 없는 경우에만\n 앱 없이 이용이 가능해요",
    messages: [
      "아쉽지만 쏘카일레클을 설치해서 이용해주세요.\n 오래 걸리지 않아요!"
    ]
  },
  KAKAO_LOGIN_ERROR: {
    title: "카카오 로그인에 실패했습니다.",
    messages: ["다시 시도해주시면 분명 좋은 시간이 될 거에요!"]
  },
  KAKAO_NETWORK_ERROR: {
    title: "일시적인 네트워크 오류가 발생했습니다.",
    messages: [
      "시크릿 브라우저로는 로그인이 불가합니다.\n일반 브라우저로 전환하여 이용해 주세요.",
      "iOS 17.4 이상의 OS를 사용한다면\n설정>사파리>IP 주소 가리기를\n‘트래커만’으로 설정해주세요."
    ]
  }
};

export enum AUTH_SERVER_ERROR_CODE {
  ALREADY_COMPLETED = "007-001",
  ALREADY_REGULAR_USER = "007-002"
}

export enum RIDING_SERVER_ERROR_CODE {
  CONTROL_FAIL = "007-003",
  NOT_AVAILABLE = "007-005",
  NOT_AVAILABLE_LOCATION = "007-004"
}
